document.addEventListener('DOMContentLoaded', () => {
  // Store current page title
  document.Nat = document.Nat || {}
  document.Nat.currentPageTitle = document.title
})

document.addEventListener('visibilitychange', () => {
  if (!/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent) && typeof natVars !== 'undefined') {
    processAnimation(natVars.anim_type)
  }
})

const effect = {
  blink: () => {
    if (natVars.anim_titles.length && natVars.anim_titles.length > 1) {
      document.title = natVars.anim_titles[document.Nat.titleIndex]
      document.Nat.titleIndex++
      if (document.Nat.titleIndex === natVars.anim_titles.length) {
        document.Nat.titleIndex = 0
      }
    } else if (document.title !== '\u200E') {
      document.title = '\u200E'
    } else {
      document.title = natVars.anim_titles[0]
    }
  },
  scroll: () => {
    if (typeof document.Nat.scrollingText === 'undefined') {
      document.Nat.scrollingText = `${natVars.anim_titles.join(' ')} `
    }
    const text = document.Nat.scrollingText
    document.title = text
    if (text.substring(0, 1) === ' ') {
      document.Nat.scrollingText = text.substring(2) + text.substring(0, 2)
    } else {
      document.Nat.scrollingText = text.substring(1) + text.substring(0, 1)
    }
  },
  typing: () => {

  }
}

function processAnimation(type) {
  if (typeof effect[type] !== 'function' || typeof natVars === 'undefined') {
    return false
  }

  if (document.visibilityState === 'hidden') {
    document.Nat.titleIndex = 0
    if (natVars.anim_delay) {
      setTimeout(() => {
        // We need to retest document visibility to avoid to start animation  if document is became visible before stating delay
        // (ie: when you move tab, document visibility switch very fast visible > hidden > visible
        if (document.visibilityState === 'hidden') {
          // @todo: https://stackoverflow.com/questions/46463589/why-setinterval-runs-slower-than-expected
          // @see: http://www.techtricky.com/javascript-code-to-scrolling-page-title/
          document.Nat.interval = setInterval(effect[type], parseInt(natVars.anim_speed, 10))
        }
      }, natVars.anim_delay)
    } else {
      document.Nat.interval = setInterval(effect[type], parseInt(natVars.anim_speed, 10))
    }
  } else {
    document.title = document.Nat.currentPageTitle
    if (typeof document.Nat.interval !== 'undefined') {
      document.Nat.interval = clearInterval(document.Nat.interval)
      document.Nat.scrollingText = undefined
    }
  }
}
